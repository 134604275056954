import PropTypes from "prop-types";
import './ProductLinkDropdownButton.css'
import React, {useState, useEffect, useRef} from "react";
import ProductLinkFormComponent from "./ProductLinkFormComponent";

function ProductLinkDropdownButtonComponent(props) {
    const [active, setActive] = useState('');
    const ref = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setActive('');
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    function changeActive() {
        if(active !== ' active')
            setActive(' active');
        else
            setActive('');
    }

    function showProperty(icon_path, text, link, onClick) {
        let target = icon_path.length ? '_blank' : ''
        return (
            <li className={'link-li'}>

                <div className={'link-li-div'}>
                    <img src={icon_path} className={'link-icon'}/>
                    <a href={link} className={'link-text'} target={target} onClick={() => {
                        if (onClick !== undefined) {
                            onClick()
                        }
                    }}>
                        {text}
                    </a>

                </div>
            </li>
        )
    }

    function showForm() {
        props.handler()
        changeActive()
    }

    if (props.linkVi && props.linkOzon && props.handler) {
        return (
            <div ref={ref} className={'product-link-dropdown-button'}>
                <a onClick={() => {
                    changeActive();
                }} className="product-ozon-link-button" target="_blank">
                    КУПИТЬ
                </a>
                <div className={'outer-menu'}>
                    <div className={'inner-menu'}>
                        <ul className={"menu cleafix" + active}>
                            <li className={"parent" + active}>
                                <ul className={"children" + active}>
                                    {showProperty('./images/link/vi.png', 'Все инструменты', props.linkVi, undefined)}
                                    {showProperty('./images/link/ozon.png', 'OZON', props.linkOzon, undefined)}

                                    <li className={'link-li'}>
                                        <div className={'link-li-div'}>
                                            <img src={'./images/link/wholesale.svg'} className={'link-icon'}/>
                                            <a className={'link-text'} onClick={() => {
                                                showForm()
                                            }}>Опт (от 10 шт)</a>
                                        </div>
                                    </li>

                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <></>
        )
    }
}

ProductLinkDropdownButtonComponent.propTypes = {
    linkVi: PropTypes.string,
    linkOzon: PropTypes.string,
    handler: PropTypes.any
};

export default ProductLinkDropdownButtonComponent;