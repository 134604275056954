import logo from './logo.svg';
import './App.css';
import TopBarComponent from "./components/TopBar/TopBarComponent";
import CarouselComponent from "./components/Carousel/CarouselComponent";
import ProductComponent from "./components/Trash/Product/ProductComponent";
import FeaturesComponent from "./components/Trash/Features/FeaturesComponent";
import HowToUseComponent from "./components/Sections/HowToUse/HowToUseComponent";
import ContactComponent from "./components/Trash/Contact/ContactComponent";
import FooterComponent from "./components/Footer/FooterComponent";
import ConstructionComponent from "./components/Sections/Main/Construction/ConstructionComponent";
import ComparisonComponent from "./components/Sections/Comparison/ComparisonComponent";
import MainComponent from "./components/Sections/Main/MainComponent";
import AdvantagesComponent from "./components/Sections/Advantages/AdvantagesComponent";
import RecommendationComponent from "./components/Sections/Recommendation/RecommendationComponent";
import BackgroundComponent from "./components/Common/BackgroundComponent";
import LinkComponent from "./components/Sections/Link/LinkComponent";
import InWorkComponent from "./components/Trash/InWork/InWorkComponent";
import HowToBuyTableComponent from "./components/Sections/HowToBuy/HowToBuyTable/HowToBuyTableComponent";
import HowToBuyComponent from "./components/Sections/HowToBuy/HowToBuyComponent";
import ProductLinkToastComponent
    from "./components/Sections/HowToBuy/HowToBuyTable/ProductLink/ProductLinkToastComponent";
import React from "react";

function App() {


    /*todo change photo*/
    /*
    window.addEventListener('scroll', function() {
        var scroll = window.pageYOffset || document.documentElement.scrollTop;
        if(scroll > 50) {
            if(document.getElementsByClassName('topBar').length) {
                document.getElementsByClassName('topBar')[0].className = 'topBar is-scrolled';
                document.getElementsByClassName('logo-color')[0].style.opacity = 1;
                document.getElementsByClassName('logo-no-color')[0].style.opacity = 0;
            }
        } else {
            if(document.getElementsByClassName('topBar is-scrolled').length) {
                document.getElementsByClassName('topBar is-scrolled')[0].className = 'topBar';
                document.getElementsByClassName('logo-color')[0].style.opacity = 0;
                document.getElementsByClassName('logo-no-color')[0].style.opacity = 1;
            }
        }
    });*/


    /*todo changed features to construction*/
    return (
        <div className="App">
            <header className="App-header">
                <CarouselComponent/>
                <TopBarComponent/>
                <MainComponent/>
                <AdvantagesComponent/>
                <HowToUseComponent/>
                <RecommendationComponent/>
                <HowToBuyComponent/>
                <FooterComponent/>
            </header>
        </div>
    );
}

export default App;
