import React, {Component, useEffect, useState} from "react";
import PropTypes from "prop-types";
import 'react-photo-view/dist/react-photo-view.css';
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import teaserComponent from "./Teaser/TeaserComponent";
import TeaserComponent from "./Teaser/TeaserComponent";
import TeaserMinimalComponent from "./TeaserMinimal/TeaserMinimalComponent";
import MobileCarouselComponent from "./MobileCarousel/MobileCarouselComponent";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import './Carousel.css'


const CarouselComponent = () => {
    const renderArrowPrev = (clickHandler) => {
        return (
            <button onClick={clickHandler} className={'control-arrow control-prev'}>
                <img src="./images/carousel/prev_image.svg" alt="Previous" />
            </button>
        );
    };

    const renderArrowNext = (clickHandler) => {
        return (
            <button onClick={clickHandler} className={'control-arrow control-next'}>
                <img src="./images/carousel/next_image.svg" alt="Next"  />
            </button>
        );
    };

    const [carouselImages, setCarouselImages] = useState([])

    const [carouselImagePaths, setCarouselImagePaths] = useState([])

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        getCarouselImagePaths(windowWidth)
    }, [windowWidth])

    async function getCarouselImagePaths() {


        let arr = []
        let rounded_width = Math.round(windowWidth / 1000) * 1000
        if(rounded_width === 0) {
            rounded_width = 1000
        }
        for(let i=3; i < 8; i++) {
            arr.push({
                originalSrc: './images/carousel/pic' + i + '.jpg',
                resizedSrc: './images/carousel/' + rounded_width + 'px_pic' + i + '.jpg'
            })
        }
        setCarouselImagePaths(arr)
    }


    useEffect(()=>{

        setTimeout(()=>{

            var css = '.carousel.carousel-slider .control-arrow:hover  { background: none }';
            var style = document.createElement('style');

            if (style.styleSheet) {
                style.styleSheet.cssText = css;
            } else {
                style.appendChild(document.createTextNode(css));
            }

            document.getElementsByTagName('head')[0].appendChild(style);

            let dots = document.getElementsByClassName('dot')
            let dotSelected = document.getElementsByClassName('dot selected')[0]
            let pictures = document.getElementsByClassName('picture')

            for(let i = 0; i < dots.length; i++) {
                dots[i].style.width = '12px'
                dots[i].style.height = '12px'

                /*todo change photo*/
                //pictures[i].style.width = '700px'
                //pictures[i].style.height = '700px'
                //pictures[i].style.opacity = 1

            }

            let content = document.getElementsByClassName('minimal-content')[0]

            /*todo default*/
            //content.style.animationName = 'fadeInLeft'
            content.style.animationName = 'fadeInRight'


        }, 100)
    }, [carouselImagePaths])


    /*todo carousel/change photo*/

    function carouselVideoInner(src) {
        return (
            <video autoPlay loop muted className="carousel-image-inner">
                <source src={src} type="video/mp4"/>
            </video>
        )
    }

    function carouselImage(path, index, title, description) {
        if(index !== 0) {
            return (
                <PhotoView key={index} src={path.originalSrc}>
                    <div className={'carousel-image-outer'}>
                        <img className={'carousel-image-inner'} src={path.resizedSrc}/>
                    </div>
                </PhotoView>
            )
        } else {
            return (
                <PhotoView key={index} src={path.originalSrc}>
                    <div className={'carousel-image-outer'}>
                        {carouselImageInner(path.resizedSrc)}
                        <TeaserMinimalComponent
                            title={title}
                            description={description}
                        />
                    </div>
                </PhotoView>

            )
        }

    }

    function carouselImageInner(src) {
        return (
            <img className={'carousel-image-inner'} src={src}/>
        )
    }

    return (

        <>
            <div className={'parent'}>

                <Carousel
                    showStatus={false}
                    showThumbs={false}
                    swipeable={true}
                    renderArrowPrev={renderArrowPrev}
                    renderArrowNext={renderArrowNext}
                    className={'carousel-updated'}
                >




                    {carouselImagePaths.map((path, index) => (
                        <PhotoProvider>

                            <div key={index}>
                                {carouselImage(path, index, 'Попали в арматуру?', 'РЕШЕНИЕ ЕСТЬ!')}
                            </div>

                        </PhotoProvider>
                    ))}

                </Carousel>

            </div>


        </>


    );

};


export default CarouselComponent;
