import React from "react";
import PropTypes from "prop-types";
import './HowToUseElementBlock.css'

function HowToUseElementBlockComponent(props) {

    function showArrow() {
        if(props.hasArrow) {
            return (
                <div className={'arrow-field'}>
                    <img src={'arrow.svg'} className={'arrow'}/>
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    function showImage() {
        return (
            <div className={'how-to-use-element-image-field'}>
                <img className={'how-to-use-element-image'} src={props.image}/>
            </div>
        )
    }

    function showText() {
        //<h2 className={'how-to-use-element-title'}>{props.title}</h2>
        return (
            <div className={'how-to-use-element-text'}>
                <div className={'how-to-use-element-text-centered'}>
                    <p className={'how-to-use-element-description'}>{props.description}</p>
                </div>
            </div>
        )
    }



    return (
        <div className={'how-to-use-element-component'}>
            <div className={'how-to-use-element-content'}>
                {showText()}
                {showImage()}
            </div>
        </div>
    )
}

HowToUseElementBlockComponent.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    hasArrow: PropTypes.bool
}

export default HowToUseElementBlockComponent
