import React from "react";
import PropTypes from "prop-types";
import './HowToUse.css'
import '../SectionComponent'
import SectionComponent from "../SectionComponent";
import HowToUseElementBlockComponent from "./HowToUseElementBlock/HowToUseElementBlockComponent";
import ReactPlayer from "react-player";

function HowToUseComponent(props) {

    function HowToUseRow(array) {
        return (
            <div className={'how-to-use-elements-list-horizontal'}>
                <HowToUseElementBlockComponent
                    image={array[0].image}
                    title={array[0].title}
                    description={array[0].description}
                    hasArrow={true}
                />
                <HowToUseElementBlockComponent
                    image={array[1].image}
                    title={array[1].title}
                    description={array[1].description}
                    hasArrow={true}
                />
                <HowToUseElementBlockComponent
                    image={array[2].image}
                    title={array[2].title}
                    description={array[2].description}
                    hasArrow={false}
                />
            </div>
        )
    }

    function insideView() {
        return (
            <div className={'how-to-use-elements-list-group'}>
                {HowToUseRow([
                    {
                        image: './images/how_to_use/pic1.jpg',
                        title: 'Этап 1',
                        description: '1. Закрепить бур в патроне перфоратора'
                    }, {
                        image: './images/how_to_use/pic2.jpg',
                        title: 'Этап 2',
                        description: '2. Выставить режим «сверление с ударом»'
                    }, {
                        image: './images/how_to_use/pic3.jpg',
                        title: 'Этап 3',
                        description: '3. Просверлить буром до арматуры'
                    }
                ])}

                {HowToUseRow([
                    {
                        image: './images/how_to_use/pic4.jpg',
                        title: 'Этап 4',
                        description: '4. Заменить бур на сверло по арматуре'
                    }, {
                        image: './images/how_to_use/pic5.jpg',
                        title: 'Этап 5',
                        description: '5. Выставить режим «сверление»'
                    }, {
                        image: './images/how_to_use/pic6.jpg',
                        title: 'Этап 6',
                        description: '6. Просверлить арматуру'
                    }
                ])}

                {HowToUseRow([
                    {
                        image: './images/how_to_use/pic7.jpg',
                        title: 'Этап 7',
                        description: '7. Заменить сверло по арматуре на бур'
                    }, {
                        image: './images/how_to_use/pic8.jpg',
                        title: 'Этап 8',
                        description: '8. Выставить режим «сверление с ударом»'
                    }, {
                        image: './images/how_to_use/pic9.jpg',
                        title: 'Этап 9',
                        description: '9. Сверлить буром до требуемой глубины'
                    }
                ])}



            </div>
        )

    }

    return (
        <SectionComponent
            title={'Как пользоваться'}
            description={''}
            insideView={insideView()}
            id={'how-to-use-component'}
            classSection={'section-resizable'}
            descriptionTextAlign={'center'}
        />
    )
}

HowToUseComponent.propTypes = {}

export default HowToUseComponent
