import React from "react";
import PropTypes from "prop-types";
import './Main.css'
import '../SectionComponent'
import SectionComponent from "../SectionComponent";
import ConstructionComponent from "./Construction/ConstructionComponent";
import {GetTextWithBoldParts} from "../../../utils/TextFunctions";

function MainComponent(props) {


    function showAdvanced(text, path) {
        return (
            <div className={'advanced'}>
                <img src={path} className={'advanced-icon'}/>
                <p className={'advanced-text'}>{GetTextWithBoldParts(text)}</p>
            </div>
        )
    }

    function insideView() {
        return (
            <div className={'construction-body'}>

                <p className={'text-main'}>{GetTextWithBoldParts('Сверло &nbsp&nbspпо &nbsp&nbspарматуре &nbsp&nbspили &nbspарматурный &nbspрезак &nbspобеспечивает &nbspрезку &nbspарматуры, &nbspзаложенной в железобетонные изделия.')}</p>

                <p className={'mobile-text-main'}>{GetTextWithBoldParts('Сверло по арматуре или арматурный резак обеспечивает резку арматуры, заложенной в железобетонные изделия.')}</p>

                <div className={'desktop-construction'}>
                    <ConstructionComponent/>

                    <div className={'advanced-description-outer'}>
                        <div className={'advanced-description-inner'}>
                            {showAdvanced('<b>Хвостовик SDS-PLUS</b>', './images/main/icon1.svg')}
                            {showAdvanced('<b>Работа в ударном режиме запрещена!</b>', './images/main/icon2.svg')}
                        </div>
                    </div>
                </div>

                <div className={'mobile-construction'}>
                    <img src={'./images/main/mobile-construction.png'} className={'mobile-construction-image'}></img>
                </div>

                <div className={'construction-description'}>
                    <p className={'text-main'}>{GetTextWithBoldParts('По конструкции схожи с коронками: полый цилиндрический корпус с твердосплавными резцами специальной формы на режущей кромке.')}</p>
                    <p className={'text-main'}>{GetTextWithBoldParts('Вентиляционное &nbsp&nbspотверстие &nbsp&nbspи &nbsp&nbspспираль &nbsp&nbspс &nbsp&nbspширокой &nbsp&nbspканавкой &nbsp&nbspслужат &nbsp&nbspдля &nbspохлаждения и удаления металлической стружки и сверлильной пыли.')}</p>

                    <p className={'mobile-text-main'}>{GetTextWithBoldParts('По конструкции схожи с коронками: полый цилиндрический корпус с твердосплавными резцами специальной формы на режущей кромке.')}</p>
                    <p className={'mobile-text-main'}>{GetTextWithBoldParts('Вентиляционное отверстие и спираль с широкой канавкой служат для охлаждения и удаления металлической стружки и сверлильной пыли.')}</p>

                </div>
            </div>
        )
    }

    /*




    */

    /*

                    <p className={'text-main'}>{GetTextWithBoldParts('<b>Диапазон диаметров</b>: от 10 до 32 мм.')}</p>
                <p className={'text-main'}>{GetTextWithBoldParts('<b>Хвостовик</b>: SDS-plus.')}</p>


     */

    return (
        <SectionComponent
            title={'Сверло по арматуре'}
            description={'При &nbsp&nbspсверлении &nbsp&nbspконструкций &nbsp&nbspиз &nbspжелезобетона &nbspвозможно &nbspпопадание &nbspбуром &nbspв &nbspарматуру. &nbspБуры не предназначены для сверления металла – для прохождения арматуры в армированном бетоне применяют специальные сверла.'}
            mobileDescription={'При сверлении конструкций из железобетона возможно попадание буром в арматуру. Буры не предназначены для сверления металла – для прохождения арматуры в армированном бетоне применяют специальные сверла.'}
            insideView={insideView()}
            id={'main-component'}
            classSection={'section'}
            descriptionTextAlign={'justify'}
            descriptionWidth={1320}
        />
    )
}

MainComponent.propTypes = {

}

export default MainComponent
