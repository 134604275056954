import React, {useEffect, useRef, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import './ProductLinkForm.css'
import PropTypes from "prop-types";
import ProductLinkDropdownButtonComponent from "./ProductLinkDropdownButtonComponent";
import PhoneInput from 'react-phone-number-input/input'
import emailjs from 'emailjs-com';
import ProductLinkToastComponent from "./ProductLinkToastComponent";

function ProductLinkFormComponent(props) {
    const ref = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false)
    const [formPhoneNumber, setFormPhoneNumber] = useState('8')
    const [formParams, setFormParams] = useState({
        city: '',
        contact: '',
        mail: '',
        phone: '',
    })
    const [formFile, setFormFile] = useState(null)
    const [firstTime, setFirstTime] = useState(true)
    const [duration, setDuration] = useState(0)
    const [reactor, setReactor] = useState(false)


    const sendForm = () => {
        setFirstTime(false)
        if(Object.values(formParams).every(value =>
            value !== undefined &&
            value !== null &&
            value !== ''
        ) && formParams['phone'] !== '8') {
            uploadAttachment()
            closeModal()
        }
    }



    const uploadAttachment = async () => {
        const formData = new FormData();
        formData.append('attachment', formFile);

        const response = await fetch('https://xn--80aaemc7aioajifcc0aq.xn--p1ai/api/upload', {
            method: 'POST',
            body: formData,
        });


        if (!response.ok) {
            throw new Error('Ошибка сети или сервера');
        }

        const attachmentPath = await response.text();

        setDuration(5000)
        setReactor(!reactor)
        await sendEmail(attachmentPath)
    };

    const sendEmail = (attachmentPath) => {
        let sendingFormParams = formParams
        if(attachmentPath) {
            sendingFormParams['attachment'] = attachmentPath
        }
        emailjs.send('praktika-rus', 'default_template', sendingFormParams, 'XxNMlAsscXBbLqD-R')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
            }, (error) => {
                console.log('FAILED...', error);
            });
    };

    const setFormVariableValue = (variableName, value) => {
        setFormParams(prevParams => ({
            ...prevParams,
            [variableName]: value
        }));
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                closeModal()
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    function closeModal() {
        props.handleClose()
    }

    function getClassNameParamInput(variableName) {
        if ((formParams[variableName].length === 0) && !firstTime)  {
            return 'red-modal-param-input'
        } else {
            return 'modal-param-input'
        }
    }

    function showRedSubscription(variableName) {
        if ((formParams[variableName].length === 0) && !firstTime) {
            return (
                <div className={'red-composition'}>
                    <img className={'red-error'} src='./images/common/error.svg'></img>
                    <p className={'red-subscription'}>Поле обязательно для заполнения</p>
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    function showDefaultInput(title, variableName) {
        return (
            <div className={'modal-param-space'}>
                <p className={'modal-param-title'}>{title}</p>
                <input
                    className={getClassNameParamInput(variableName)}
                    onChange={(e) => {
                        setFormVariableValue(variableName, e.target.value)
                    }}
                />
                {showRedSubscription(variableName)}
            </div>
        )

    }

    function showPhoneInput(title, variableName) {
        return (
            <div className={'modal-param-space'}>
                <p className={'modal-param-title'}>{title}</p>
                <PhoneInput
                    country="RU"
                    value={formPhoneNumber}
                    onChange={setFormPhoneNumber}
                />
            </div>
        )
    }
    useEffect(() => {
        setFormVariableValue('phone', formPhoneNumber)
    }, [formPhoneNumber])

    function showFileInput(title, variableName) {
        return (
            <div>
                <div className={'modal-param-space'}>
                    <p className={'modal-param-title'}>{title}</p>

                    <div className='background-input' aria-disabled="false">
                        {showStatus()}
                        <input
                            className={'input-file'}
                            type="file"
                            accept='.jpg, .png'
                            onChange={(e)=>{
                                setFormFile(e.target.files[0])
                                setIsLoaded(true)
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    function showStatus() {
        if(!isLoaded) {
            return (
                <div className="before-upload-field">
                    <img src="./images/common/upload.svg"/>
                    <div className="select-a-file">
                        <p className="select-a-file-text">Выберите файл</p>
                        <p className="select-a-file-text-black">или переместите сюда</p>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="after-upload-field">
                    <img src="./images/common/ok.svg"/>
                </div>
            )
        }
    }

    function showModalContent() {
        return (
            <>
                {showDefaultInput('Город:', 'city')}
                {showDefaultInput('Контактное лицо:', 'contact')}
                {showDefaultInput('Почта:', 'mail')}
                {showPhoneInput('Телефон:', 'phone')}
                {showFileInput('Реквизиты', 'attachment')}
            </>
        )
    }


    function showModal() {
        let active = props.active ? 'active' : 'nonactive'
        return (
            <div className={'modal-component'}>
                <div className={'modal-overlay ' + active}></div>
                <div className={'modal ' + active} ref={ref}>
                    <div className={'modal-header'}>
                        <div className={'modal-title'}></div>
                        <img src={'./images/link/cancel.svg'} className={'cancel-button'} onClick={() => {
                            closeModal()
                        }}></img>
                    </div>
                    <div className={'modal-content'}>
                        {showModalContent()}
                    </div>
                    <div className={'under-modal-divider'}></div>

                    <a
                        onClick={() => {
                            sendForm()
                        }}
                        className="product-submit-link-button" target="_blank"
                        id={'send-button'}
                    >
                        ОТПРАВИТЬ
                    </a>
                </div>
            </div>
        )
    }

    return (
        <>
            {showModal()}
            <ProductLinkToastComponent
                title={'Отправлено'}
                description="Дождитесь ответа в ближайшее время"
                duration={duration}
                reactor={reactor}
            />
        </>
    );
}


ProductLinkFormComponent.propTypes = {
    active: PropTypes.bool,
    handleClose: PropTypes.any
};


export default ProductLinkFormComponent;