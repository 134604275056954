import React, { useState, useEffect } from 'react';
import './ProductLinkToast.css';
import PropTypes from "prop-types";

function ProductLinkToastComponent(props) {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsActive(true);
        }, 100); // Задержка перед отображением

        return () => clearTimeout(timer);
    }, [props.reactor]);

    useEffect(() => {
        if (isActive) {
            const timer = setTimeout(() => {
                setIsActive(false);
            }, props.duration);

            return () => clearTimeout(timer);
        }
    }, [isActive, props.duration]);



    const closeToast = () => {
        setIsActive(false);
    };

    return (
        <div className={`toast ${isActive ? 'active' : ''}`}>
            <div className="toast-content">
                <i className="fas fa-solid fa-check check">
                    <img src={'./images/common/white_ok.svg'} className={'toast-ok'}></img>
                </i>
                <div className="message">
                    <span className="text text-1">{props.title}</span>
                    <span className="text text-2">{props.description}</span>
                </div>
            </div>
            <img className="fa-solid fa-xmark close" src={'./images/link/notification_cancel.svg'} onClick={closeToast}></img>
            <div className={`${isActive ? 'progress active' : ''}`}></div>
        </div>
    );
};


ProductLinkToastComponent.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    duration: PropTypes.number,
    openHandler: PropTypes.func,
    reactor: PropTypes.bool
};


export default ProductLinkToastComponent;