import React, {useState} from "react";
import PropTypes from "prop-types";
import './Section.css'
import {GetTextWithBoldParts} from "../../utils/TextFunctions";

function SectionComponent(props) {

    function showDescription() {
        if(props.description.length > 0) {
            return (

                <>
                    <p className={'mobile-description'} style={{
                        maxWidth: props.descriptionWidth + 'px',
                        textAlign: props.descriptionTextAlign
                    }}>
                        {GetTextWithBoldParts(props.mobileDescription)}
                    </p>
                    <p className={'description'} style={{
                        maxWidth: props.descriptionWidth + 'px',
                        textAlign: props.descriptionTextAlign
                    }}>
                        {GetTextWithBoldParts(props.description)}
                    </p>
                </>

            )
        } else {
            return (
                <></>
            )
        }
    }

    return (
        <div className={props.classSection} id={props.id}>
            <h2 className={'title'}>
                {props.title}
            </h2>
            {showDescription()}
            <div className={'divider'}/>
            {props.insideView}
        </div>
    )
}

SectionComponent.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    mobileDescription: PropTypes.string,
    insideView: PropTypes.any,
    classSection: PropTypes.string,
    descriptionWidth: PropTypes.number,
    descriptionTextAlign: PropTypes.string
}

export default SectionComponent
