import React, {useEffect} from "react";
import PropTypes from "prop-types";
import './HowToBuyTable.css'
import {error} from "loglevel";
import ProductLinkDropdownButtonComponent from "./ProductLink/ProductLinkDropdownButtonComponent";
import _ from 'lodash';


function HowToBuyTableComponent(props) {


    function changeItem(item) {
        props.handler(item)
    }

    function showModal(item) {
        item.handler()
    }

    function showParams(params) {
        return params.map(value => <th key={value.id} width={value.width}>{value.title}</th>);
    }

    function getItemClassName(item) {
        if(JSON.stringify(item) === JSON.stringify(props.currentItem)) {
            return 'active-item'
        } else {
            return ''
        }
    }

    function showItem(item, params) {
        return params.map(param =>
            <td
                key={param.id}
                onClick={() => changeItem(item)}
                width={param.width}
            >
                {item[param.id]}
            </td>
        );
    }

    function showItems(items, params) {
        return items.map(item =>
            <tr
                key={item.link}
                className={getItemClassName(item)}
            >
                {showItem(item, params)}
            </tr>);
    }


    return (
        <table className={'custom-table'}>
            <thead className={'thead'}>
            <tr>{showParams(props.params)}</tr>
            </thead>
            <tbody>{showItems(props.items, props.params)}</tbody>
        </table>
    )
}

HowToBuyTableComponent.propTypes = {
    currentItem: PropTypes.any,
    params: PropTypes.array,
    items: PropTypes.array,
    handler: PropTypes.func
}

export default HowToBuyTableComponent
