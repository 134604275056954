import React, { useState } from 'react';
import PropTypes from "prop-types";
import './SideBar.css';
import TopBarElementComponent from "../TopBarElement/TopBarElementComponent";
import SideBarElementComponent from "./SideBarElement/SideBarElementComponent"; // Предполагается, что CSS находится в Sidebar.css

const SideBarComponent = (props) => {
    const [isSidebarActive, setSidebarActive] = useState(false);

    const toggleSidebar = () => {
        setSidebarActive(!isSidebarActive);
    };



    const handleKeyUp = (e) => {
        if (e.keyCode === 27) {
            toggleSidebar();
        }
    };

    React.useEffect(() => {
        document.addEventListener('keyup', handleKeyUp);
        return () => {
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    function getReversedList(list) {
        const reversedList = [];
        for (let i = list.length - 1; i >= 0; i--) {
            reversedList.push(list[i]);
        }
        return reversedList;
    }

    function offSidebar() {
        if(isSidebarActive === true) {
            setSidebarActive(false);
        }
    }

    function showElements(elements) {
        elements = getReversedList(elements)
        return elements.map((value, index) => {
            return (
                <li className={`sidebar-item ${isSidebarActive ? 'active' : ''}`}>
                    <SideBarElementComponent
                        title={value.title}
                        target_id={value.target_id}
                        animationOffset={0}
                        handler={()=> {
                            offSidebar()
                        }}
                    />
                </li>
            )
        })
    }

    return (
        <div>
            <div className={`nav-right visible-xs ${isSidebarActive ? 'active' : ''}`} onClick={toggleSidebar}>
                <div className={`button ${isSidebarActive ? 'active' : ''}`} id="btn">
                    <div className="bar top"></div>
                    <div className="bar middle"></div>
                    <div className="bar bottom"></div>
                </div>
            </div>
            <main className={`${isSidebarActive ? 'move-to-left' : ''}`}>
                <nav>
                    <div className={`nav-right hidden-xs ${isSidebarActive ? 'active' : ''}`} onClick={toggleSidebar}>
                        <div className={`button ${isSidebarActive ? 'active' : ''}`} id="btn">
                            <div className="bar top"></div>
                            <div className="bar middle"></div>
                            <div className="bar bottom"></div>
                        </div>
                    </div>
                </nav>
                <a href="https://codepen.io/tonkec/" className="ua" target="_blank">
                    <i className="fa fa-user"></i>
                </a>
            </main>
            <div className={`sidebar ${isSidebarActive ? 'active' : ''}`}>
                <ul className="sidebar-list">
                    {showElements(props.elements)}
                </ul>
            </div>
        </div>
    );
};

SideBarComponent.propTypes = {
    elements: PropTypes.array
}

export default SideBarComponent;