import React from "react";
import PropTypes from "prop-types";
import './TopBar.css'
import TopBarElementComponent from "./TopBarElement/TopBarElementComponent";
import SideBarComponent from "./SideBar/SideBarComponent";

/*todo change photo*/
/* <img src={'/logo_mini.png'} alt={'image'} className={'logo-no-color'}/> */


/*todo remove section product*/
/*

                <TopBarElementComponent
                    title={'Продукт'}
                    target_id={'product-component'}
                    animationOffset={0}
                />
*/


function TopBarComponent(props) {
    /*todo change topbar to blue*/
    const elements = [
        {
            title: 'Купить',
            target_id: 'how-to-buy-component',
            visibilityHeight: 2000,
            isFadeUpOnStart: false
        },
        {
            title: 'Рекомендации',
            target_id: 'recommendation-component',
            visibilityHeight: 2000,
            isFadeUpOnStart: false
        },
        {
            title: 'Как пользоваться',
            target_id: 'how-to-use-component',
            visibilityHeight: 2300,
            isFadeUpOnStart: false
        },
        {
            title: 'Преимущества',
            target_id: 'advantages-component',
            isFadeUpOnStart: false
        },
        {
            title: 'Сверло по арматуре',
            target_id: 'main-component',
            visibilityHeight: 2300,
            isFadeUpOnStart: true
        },
    ]

    function showElements(elements) {

        return elements.map((value, index) => {
            return (
                <TopBarElementComponent
                    title={value.title}
                    target_id={value.target_id}
                    animationOffset={0}
                    visibilityHeight={value.visibilityHeight}
                    isFadeUpOnStart={value.isFadeUpOnStart}
                />
            )
        })

    }

    return (
        <div className={'topBar'}>
            <div className={'offset'}>
                <div className={'image-div'}>
                    <img src={'./images/topbar/logo.svg'} alt={'image'} className={'logo-color'}/>
                </div>
                {showElements(elements)}

            </div>
            <div className={'sideBar'}>
                <SideBarComponent
                    elements={elements}
                />
            </div>
        </div>
    )
}

TopBarComponent.propTypes = {

}

export default TopBarComponent

