import React from "react";
import PropTypes from "prop-types";
import '../SectionComponent'
import SectionComponent from "../SectionComponent";
import {GetTextWithBoldParts} from "../../../utils/TextFunctions";
import VideoPlayerComponent from "../../VideoPlayer/VideoPlayerComponent";
import './Recommendation.css'



function RecommendationComponent(props) {

    function insideView() {

        const options = {
            autoplay: true,
            controls: true,
            responsive: true,
            fluid: true,
            sources: [{
                src: './videos/drilling.mp4',
                type: 'video/mp4'
            }]
        };

        return (
            <div>
                <div className={'text-recommendation-div'}>
                    <p className={'text-recommendation'}>{GetTextWithBoldParts('При сверлении арматуры регулярно <b>останавливайтесь</b> и <b>охлаждайте сверло</b>, чтобы предотвратить его перегревание.')}</p>
                    <p className={'text-recommendation'}>{GetTextWithBoldParts('<b>ВАЖНО!</b> Сверлами по арматуре допустима работа <b>только в безударном режиме</b>.')}</p>
                </div>

                <div className={'video-outer-field'}>
                    <div className={'video-inner-field'}>
                        <VideoPlayerComponent
                            options={options}
                        />
                    </div>
                </div>

            </div>

        )
    }

    return (
        <SectionComponent
            title={'Рекомендации по работе'}
            description={''}
            mobileDescription={''}
            insideView={insideView()}
            id={'recommendation-component'}
            classSection={'section-blue'}
            descriptionWidth={1235}
            descriptionTextAlign={'justify'}
        />
    )
}

RecommendationComponent.propTypes = {}

export default RecommendationComponent
