import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {type} from "@testing-library/user-event/dist/type";

function TopBarElementComponent(props) {

    const [isSelected, setIsSelected] = useState(false)
    const [isOnceVisibled, setIsOnceVisibled] = useState(false)

    useEffect(() => {
        if(isOnceVisibled) {
            const element = document.getElementById(props.target_id);
            element.style.animationName = 'fadeInUp'
        }
    }, [isOnceVisibled])


    useEffect(() => {
        const handleScroll = () => {
            const element = document.getElementById(props.target_id);
            if(element) {
                const elementRect = element.getBoundingClientRect();
                const isVisibleForAnimating = elementRect.top-props.animationOffset< window.innerHeight
                const isVisible = elementRect.top+500 < window.innerHeight && elementRect.top+1200 > window.innerHeight;

                setIsSelected(isVisible);
                if(!isOnceVisibled && isVisibleForAnimating) {
                    setIsOnceVisibled(true)
                }

            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function scrollToSmoothly(pos, slowdown) {
        var currentPos = window.pageYOffset;
        window.requestAnimationFrame(function step() {

            if(Math.abs(pos - currentPos) > 10) {
                currentPos = currentPos+(pos-currentPos)/slowdown
                window.scrollTo(0, currentPos);
                window.requestAnimationFrame(step);
            }
        });
    }


    function handleClick() {
        const element = document.getElementById(props.target_id)
        scrollToSmoothly(element.getElementsByClassName('title')[0].offsetTop-200, 10)
        props.handler()
    }


    return (
        <a
            className="sidebar-anchor"
            onClick={() => {
                handleClick()
            }}
        >{props.title}</a>
    )
}

TopBarElementComponent.propTypes = {
    title: PropTypes.string,
    target_id: PropTypes.string,
    animationOffset: PropTypes.number,
    handler: PropTypes.any
}

export default TopBarElementComponent

