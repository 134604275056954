import React from "react";
import PropTypes from "prop-types";
import './Advantages.css'
import {GetTextWithBoldParts} from "../../../utils/TextFunctions";

function AdvantagesComponent(props) {

    let classSection = 'section-blue'
    let title = 'Преимущества'
    let description = ''
    let id = 'advantages-component'


    function showAdvantage(text) {
        return (
            <div className={'advantage'}>
                <img src={'./images/advantages/ok.svg'} className={'advantage-icon'}/>
                <li className={'advantage-text'}>{text}</li>
            </div>
        )
    }

    function showAdvantages() {
        return (
            <div className={'advantages'}>
                {showAdvantage('Быстрая скорость сверления')}
                {showAdvantage('Сверление арматуры в больших отверстиях')}
                {showAdvantage('Проход арматуры на большой глубине (до 300 мм)')}
                {showAdvantage('Остаются острыми даже при попадании на бетон')}
            </div>
        )
    }

    function showTextField() {
        return (
            <div className={'text-field-advantages'}>
                {showAdvantages()}
            </div>
        )
    }

    function showImageField() {
        return (
            <div className={'image-field-advantages'}>
                <img src={'./images/advantages/pic1.png'}  className={'image-field-advantages-element'}/>
            </div>
        )
    }

    return (
        <div className={classSection} id={id}>
            <h2 className={'title'}>
                {title}
            </h2>
            <div className={'divider'}/>
            <div className={'advantages-component'}>
                {showTextField()}
                {showImageField()}
            </div>
        </div>
    )
}

AdvantagesComponent.propTypes = {}

export default AdvantagesComponent
