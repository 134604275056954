import PropTypes from "prop-types";
import React from "react";
import './Background.css'


function BackgroundComponent(props) {
    return (
        <img src={'./images/common/test4.jpg'} className={'background'}/>
    )
}

BackgroundComponent.propsTypes = {

}

export default BackgroundComponent