import React, {useState} from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './VideoPlayer.css'
import {InView, useInView} from "react-intersection-observer";
import '../Sections/Recommendation/Recommendation.css'

export const VideoPlayerComponent = (props) => {
    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);
    const {options, onReady} = props;
    let [isRun, setIsRun] = useState(false)
    const [isMuted, setIsMuted] = useState(true);

    const [ref, inView] = useInView({
        triggerOnce: true, // Плеер будет автозапускаться только один раз при первом появлении
    });

    const handlePlay = () => {
        setIsRun(true)
    };

    const handlePause = () => {
        setIsRun(false)
    };

    React.useEffect(() => {
        if (inView && playerRef.current) {
            playerRef.current.play().catch(error => {
                console.log('Autoplay was prevented.', error);
            });
        }
    }, [inView]);


    React.useEffect(() => {
        if (!playerRef.current) {

            const videoElement = document.createElement("video-js");
            videoElement.classList.add('vjs-big-play-centered');
            videoElement.setAttribute('muted', '');
            videoElement.setAttribute('autoplay', '');
            videoRef.current.appendChild(videoElement);

            const player = playerRef.current = videojs(videoElement, options, () => {
                videojs.log('player is ready');
                onReady && onReady(player);

                // Добавление обработчиков событий
                player.on('play', handlePlay);
                player.on('pause', handlePause);
            });
        } else {
            const player = playerRef.current;

            player.autoplay(options.autoplay);
            player.src(options.sources);
        }
    }, [options, videoRef, onReady]);


    React.useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);


    React.useEffect(() => {
        const player = playerRef.current;
        if (playerRef.current && InView) {
            player.autoplay();
        }
    }, [])

    function showPlayButton() {
        if(isRun) {
            return (
                <></>
            )
        } else {
            return (
                <>
                    <div className={'field-for-button-mobile'}>
                        <img className={'play-button-mobile'} src={'./images/video/play.svg'}/>
                    </div>
                    <div className={'field-for-button'}>
                        <img className={'play-button'} src={'./images/video/play.svg'}/>
                    </div>
                </>

            )
        }
    }

    const handleUnmute = () => {
        if (playerRef.current) {
            playerRef.current.muted(false);
            setIsMuted(false);
        }
    };

    return (
        <div>
            {showPlayButton()}
            <div data-vjs-player>
                <div ref={videoRef} className={'field'}>
                </div>
            </div>
        </div>

    );
}

export default VideoPlayerComponent;