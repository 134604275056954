import PropTypes from "prop-types";
import './TeaserMinimal.css'


function TeaserMinimalComponent(props) {
    return (
        <div className={'minimal-content'}>
            <div className={'minimal-box'}>
                <h2 className={'minimal-teaser-title'}>{props.title}</h2>
                <p className={'minimal-teaser-description'}>{props.description}</p>
            </div>
        </div>

    )
}

TeaserMinimalComponent.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    linkTitle: PropTypes.string,
    leftOffset: PropTypes.number,
    topOffset: PropTypes.number
}

export default TeaserMinimalComponent
