import PropTypes from "prop-types";
import React from "react";
import './Footer.css'


function FooterComponent(props) {
    return (
        <div className={'footer'}>
            <div className={'footer-divider'}></div>
            <p className={'footer-copyright'}>Copyright © 2023. Практика. All rights reserved</p>
        </div>
    )
}

FooterComponent.propTypes = {

}

export default FooterComponent
