import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import './ConstructionTooltip.css'


function ConstructionTooltipComponent(props) {

    const [isShow, setIsShow] = useState(true)
    const [id,  setId] = useState('')

    let verticalDirection = props.isUp ? 'top' : 'bottom'
    let horizontalDirection = props.isLeft ? 'left' : 'right'
    let fadeAnimation = props.isLeft ? 'fadeInLeft' : 'fadeInRight'
    let innerSymbol = isShow ? '•' : '•'

    let styleWidthDict = {}

    let styleWidthName = props.isLeft ? 'margin-right' : 'margin-left'
    styleWidthName = !props.isUp && !props.isLeft ? 'right' : styleWidthName

    let styleWidthValue = props.isLeft ? -61 : -63
    styleWidthValue = !props.isUp && !props.isLeft ? 63 : styleWidthValue

    let styleWidthSign = props.isLeft ? 1 : 1
    styleWidthSign = !props.isUp && !props.isLeft ? -1 : styleWidthSign

    styleWidthDict[styleWidthName] = props.extraWidth ? styleWidthValue + styleWidthSign * props.extraWidth : styleWidthValue

    let defaultWidthLine = props.extraWidth ? 102 + props.extraWidth : 102

    let className = "feature-note "+ horizontalDirection + " " + verticalDirection + " wow " + fadeAnimation

    if(isShow) {
        className = "feature-note "+ horizontalDirection + " " + verticalDirection + " wow " + fadeAnimation + " show-cont"
    }

    useEffect(() => {
        setTimeout(() => {
            let texts = document.getElementsByClassName('feature-name')
            for(let i = 0; i < texts.length; i++) {
                if(texts[i].textContent === props.tooltip) {
                    let differenceWidth = texts[i].clientWidth
                    let id = 'plus-after' + i
                    setId(id)

                    var css = ".feature-note.show-cont .indicator #" + id + ".plus-after { width: " + String(defaultWidthLine + differenceWidth/2) + "px; }";
                    var style = document.createElement('style');

                    if (style.styleSheet) {
                        style.styleSheet.cssText = css;
                    } else {
                        style.appendChild(document.createTextNode(css));
                    }

                    document.getElementsByTagName('head')[0].appendChild(style);

                }
            }
        }, 100)
    }, []);

    function showTooltip() {
        if(!isShow) {
            setIsShow(true)
        } else {
            setIsShow(false)
        }
    }


    return (
        <div className={'construction-tooltip-component'}>
            <div className={className} style={{
                visibility: 'visible',
                animationName: fadeAnimation,
                animationDuration: '2s',
                position: 'relative',
                left: props.x,
                top: props.y,
            }}
                 id = {props.id}
            >
                <div className="indicator" onClick={()=>{showTooltip()}}>
                    <div className="plus-icon">

                        <span className="plus">
                            <div className={'plus-before'}></div>
                            <p className={'plus-text'}>{innerSymbol}</p>
                        </span>
                        <div className={'plus-after'} id={id}></div>
                    </div>
                </div>
                <div className="feature-name" style={styleWidthDict}>
                    <div className={'plus-before'}></div>

                    <div className={'feature-text-field'} style={
                        {
                            marginTop: -props.textTopOffset,
                            marginLeft: -props.textLeftOffset,

                        }
                    }>
                        {props.tooltip}
                    </div>
                </div>
            </div>
        </div>
    )
}

ConstructionTooltipComponent.propTypes = {
    title: PropTypes.string,
    tooltip: PropTypes.string,
    tooltipDescription: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    isUp: PropTypes.bool,
    isLeft: PropTypes.bool,
    extraWidth: PropTypes.number,
    textTopOffset: PropTypes.number,
    textLeftOffset: PropTypes.number
}

export default ConstructionTooltipComponent

