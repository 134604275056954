import React from "react";
import PropTypes from "prop-types";
import './Construction.css'
import '../../SectionComponent'
import SectionComponent from "../../SectionComponent";
import ConstructionTooltipComponent from "./ConstructionTooltip/ConstructionTooltipComponent";


function ConstructionComponent(props) {
    return (
        <div className={'main-construction'}>
            <img src={'./images/main/pic1.png'} className={'image-construction'}></img>
            <div className={'tooltips-construction'}>
                <ConstructionTooltipComponent
                    tooltip={'Твердосплавные зубья'}
                    isUp={true}
                    isLeft={true}
                    x={-225}
                    y={-45}
                    extraWidth={-40}
                    textTopOffset={15}
                />
                <ConstructionTooltipComponent
                    tooltip={'Вентиляционное отверстие'}
                    isUp={true}
                    isLeft={true}
                    x={-280}
                    y={0}
                    extraWidth={-5}
                    textTopOffset={15}
                />
                <ConstructionTooltipComponent
                    tooltip={'Диапазон диаметров:\n от 10 до 32 мм'}
                    isUp={true}
                    isLeft={false}
                    x={335}
                    y={-185}
                    extraWidth={-80}
                    textTopOffset={55}
                    textLeftOffset={-110}
                />

            </div>
        </div>
    )
}

/*
                <ConstructionTooltipComponent
                    tooltip={'УНИКАЛЬНЫЙ ДИЗАЙН СПИРАЛИ'}
                    isUp={true}
                    isLeft={true}
                    x={-307}
                    y={-55}
                    extraWidth={130}
                />
                <ConstructionTooltipComponent
                    tooltip={'ДИЗАЙН СПИРАЛЕЙ 2 + 2'}
                    isUp={false}
                    isLeft={false}
                    x={245}
                    y={-170}
                    extraWidth={202}
                />



 */

ConstructionComponent.propTypes = {

}

export default ConstructionComponent
