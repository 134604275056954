import React from "react";
import PropTypes from "prop-types";
import './HowToBuyImage.css'


function HowToBuyImageComponent(props) {
    return (
        <div className={'how-to-buy-image'}>
            <img
                src={props.currentItem.image}
                className={'how-to-buy-image-content'}
            />
        </div>
    )
}

HowToBuyImageComponent.propTypes = {
    currentItem: PropTypes.any
}

export default HowToBuyImageComponent

