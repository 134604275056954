import React, {useState} from "react";
import PropTypes from "prop-types";
import './HowToBuy.css'
import '../SectionComponent'
import SectionComponent from "../SectionComponent";
import HowToBuyTableComponent from "./HowToBuyTable/HowToBuyTableComponent";
import HowToBuyImageComponent from "./HowToBuyImage/HowToBuyImageComponent";
import ProductLinkFormComponent from "./HowToBuyTable/ProductLink/ProductLinkFormComponent";
import ProductLinkDropdownButtonComponent from "./HowToBuyTable/ProductLink/ProductLinkDropdownButtonComponent";
import ProductLinkToastComponent from "./HowToBuyTable/ProductLink/ProductLinkToastComponent";

function HowToBuyComponent(props) {


    let [modalActive, setModalActive] = useState(false)


    let params = [
        {
            id: 'article',
            title: 'Артикул',
            width: '233px'
        },

        {
            id: 'length',
            title: 'Длина',
            width: '140px'
        },

        {
            id: 'diameter',
            title: 'Диаметр',
            width: '177px'
        },




    ]

    function activateModal() {
        setModalActive(true)
    }

    function disableModal() {
        setModalActive(false)
    }


    let items = [
        {
            article: '915-120',
            diameter: '10',
            length: '200',
            image: './images/link/pic00.png',
            linkVi: 'https://www.vseinstrumenti.ru/product/sverlo-po-armature-ekspert-10h200-mm-sds-plus-praktika-915-120-9836930/',
            linkOzon: 'https://www.ozon.ru/product/sverlo-po-armature-sds-plus-praktika-10-200-seriya-ekspert-1187621909/?asb=vwdIcV4qBK7b%252BUXFW3db4Y92tdBRvs6%252BpzlDCEEEhEs%253D&asb2=XiaFSqKzmMD113-PNEFzWtr-466qk58qBwWgJsD97Z4ItcdXqIRnVxBxHg-EjG4VGtWStwfQNLSqjs4Cqe_yZA&avtc=1&avte=2&avts=1714214270&from_sku=1187706260&from_url=https%253A%252F%252Fwww.ozon.ru%252Fsearch%252F%253Ffrom_global%253Dtrue%2526text%253D%2525D1%252581%2525D0%2525B2%2525D0%2525B5%2525D1%252580%2525D0%2525BB%2525D0%2525BE%252B%2525D0%2525BF%2525D0%2525BE%252B%2525D0%2525B0%2525D1%252580%2525D0%2525BC%2525D0%2525B0%2525D1%252582%2525D1%252583%2525D1%252580%2525D0%2525B5%252B%2525D1%25258D%2525D0%2525BA%2525D1%252581%2525D0%2525BF%2525D0%2525B5%2525D1%252580%2525D1%252582&keywords=%D1%81%D0%B2%D0%B5%D1%80%D0%BB%D0%BE+%D0%BF%D0%BE+%D0%B0%D1%80%D0%BC%D0%B0%D1%82%D1%83%D1%80%D0%B5+%D1%8D%D0%BA%D1%81%D0%BF%D0%B5%D1%80%D1%82&oos_search=false',
            handler: ()=>{activateModal()}
        },

        {
            article: '915-137',
            diameter: '12',
            length: '300',
            image: './images/link/pic01.png',
            linkVi: 'https://www.vseinstrumenti.ru/product/sverlo-po-armature-ekspert-12h300-mm-sds-plus-praktika-915-137-9836918/',
            linkOzon: 'https://www.ozon.ru/product/sverlo-po-armature-sds-plus-praktika-12-300-seriya-ekspert-1187717600/?asb=vwdIcV4qBK7b%252BUXFW3db4Y92tdBRvs6%252BpzlDCEEEhEs%253D&asb2=XiaFSqKzmMD113-PNEFzWtr-466qk58qBwWgJsD97Z4ItcdXqIRnVxBxHg-EjG4VGtWStwfQNLSqjs4Cqe_yZA&avtc=1&avte=2&avts=1714214270&from_sku=1187706260&from_url=https%253A%252F%252Fwww.ozon.ru%252Fsearch%252F%253Ffrom_global%253Dtrue%2526text%253D%2525D1%252581%2525D0%2525B2%2525D0%2525B5%2525D1%252580%2525D0%2525BB%2525D0%2525BE%252B%2525D0%2525BF%2525D0%2525BE%252B%2525D0%2525B0%2525D1%252580%2525D0%2525BC%2525D0%2525B0%2525D1%252582%2525D1%252583%2525D1%252580%2525D0%2525B5%252B%2525D1%25258D%2525D0%2525BA%2525D1%252581%2525D0%2525BF%2525D0%2525B5%2525D1%252580%2525D1%252582&keywords=%D1%81%D0%B2%D0%B5%D1%80%D0%BB%D0%BE+%D0%BF%D0%BE+%D0%B0%D1%80%D0%BC%D0%B0%D1%82%D1%83%D1%80%D0%B5+%D1%8D%D0%BA%D1%81%D0%BF%D0%B5%D1%80%D1%82&oos_search=false',
            handler: ()=>{activateModal()}
        },

        {
            article: '915-144',
            diameter: '14',
            length: '300',
            image: './images/link/pic02.png',
            linkVi: 'https://www.vseinstrumenti.ru/product/sverlo-po-armature-ekspert-14h300-mm-sds-plus-praktika-915-144-9836924',
            linkOzon: 'https://www.ozon.ru/product/sverlo-po-armature-sds-plus-praktika-14-300-seriya-ekspert-1187704154/?asb=vwdIcV4qBK7b%252BUXFW3db4Y92tdBRvs6%252BpzlDCEEEhEs%253D&asb2=XiaFSqKzmMD113-PNEFzWtr-466qk58qBwWgJsD97Z4ItcdXqIRnVxBxHg-EjG4VGtWStwfQNLSqjs4Cqe_yZA&avtc=1&avte=2&avts=1714214270&from_sku=1187706260&from_url=https%253A%252F%252Fwww.ozon.ru%252Fsearch%252F%253Ffrom_global%253Dtrue%2526text%253D%2525D1%252581%2525D0%2525B2%2525D0%2525B5%2525D1%252580%2525D0%2525BB%2525D0%2525BE%252B%2525D0%2525BF%2525D0%2525BE%252B%2525D0%2525B0%2525D1%252580%2525D0%2525BC%2525D0%2525B0%2525D1%252582%2525D1%252583%2525D1%252580%2525D0%2525B5%252B%2525D1%25258D%2525D0%2525BA%2525D1%252581%2525D0%2525BF%2525D0%2525B5%2525D1%252580%2525D1%252582&keywords=%D1%81%D0%B2%D0%B5%D1%80%D0%BB%D0%BE+%D0%BF%D0%BE+%D0%B0%D1%80%D0%BC%D0%B0%D1%82%D1%83%D1%80%D0%B5+%D1%8D%D0%BA%D1%81%D0%BF%D0%B5%D1%80%D1%82&oos_search=false',
            handler: ()=>{activateModal()}
        },

        {
            article: '915-168',
            diameter: '18',
            length: '300',
            image: './images/link/pic03.png',
            linkVi: 'https://www.vseinstrumenti.ru/product/sverlo-po-armature-ekspert-18h300-mm-sds-plus-praktika-915-168-9836906/',
            linkOzon: 'https://www.ozon.ru/product/sverlo-po-armature-sds-plus-praktika-18-300-seriya-ekspert-1187711890/?asb=vwdIcV4qBK7b%252BUXFW3db4Y92tdBRvs6%252BpzlDCEEEhEs%253D&asb2=XiaFSqKzmMD113-PNEFzWtr-466qk58qBwWgJsD97Z4ItcdXqIRnVxBxHg-EjG4VGtWStwfQNLSqjs4Cqe_yZA&avtc=1&avte=2&avts=1714214270&from_sku=1187706260&from_url=https%253A%252F%252Fwww.ozon.ru%252Fsearch%252F%253Ffrom_global%253Dtrue%2526text%253D%2525D1%252581%2525D0%2525B2%2525D0%2525B5%2525D1%252580%2525D0%2525BB%2525D0%2525BE%252B%2525D0%2525BF%2525D0%2525BE%252B%2525D0%2525B0%2525D1%252580%2525D0%2525BC%2525D0%2525B0%2525D1%252582%2525D1%252583%2525D1%252580%2525D0%2525B5%252B%2525D1%25258D%2525D0%2525BA%2525D1%252581%2525D0%2525BF%2525D0%2525B5%2525D1%252580%2525D1%252582&keywords=%D1%81%D0%B2%D0%B5%D1%80%D0%BB%D0%BE+%D0%BF%D0%BE+%D0%B0%D1%80%D0%BC%D0%B0%D1%82%D1%83%D1%80%D0%B5+%D1%8D%D0%BA%D1%81%D0%BF%D0%B5%D1%80%D1%82&oos_search=false',
            handler: ()=>{activateModal()}
        },

        {
            article: '915-182',
            diameter: '22',
            length: '300',
            image: './images/link/pic04.png',
            linkVi: 'https://www.vseinstrumenti.ru/product/sverlo-po-armature-ekspert-22h300-mm-sds-plus-praktika-915-182-9836900/',
            linkOzon: 'https://www.ozon.ru/product/sverlo-po-armature-sds-plus-praktika-22-300-seriya-ekspert-1187713516/?asb=vwdIcV4qBK7b%252BUXFW3db4Y92tdBRvs6%252BpzlDCEEEhEs%253D&asb2=XiaFSqKzmMD113-PNEFzWtr-466qk58qBwWgJsD97Z4ItcdXqIRnVxBxHg-EjG4VGtWStwfQNLSqjs4Cqe_yZA&avtc=1&avte=2&avts=1714214270&from_sku=1187706260&from_url=https%253A%252F%252Fwww.ozon.ru%252Fsearch%252F%253Ffrom_global%253Dtrue%2526text%253D%2525D1%252581%2525D0%2525B2%2525D0%2525B5%2525D1%252580%2525D0%2525BB%2525D0%2525BE%252B%2525D0%2525BF%2525D0%2525BE%252B%2525D0%2525B0%2525D1%252580%2525D0%2525BC%2525D0%2525B0%2525D1%252582%2525D1%252583%2525D1%252580%2525D0%2525B5%252B%2525D1%25258D%2525D0%2525BA%2525D1%252581%2525D0%2525BF%2525D0%2525B5%2525D1%252580%2525D1%252582&keywords=%D1%81%D0%B2%D0%B5%D1%80%D0%BB%D0%BE+%D0%BF%D0%BE+%D0%B0%D1%80%D0%BC%D0%B0%D1%82%D1%83%D1%80%D0%B5+%D1%8D%D0%BA%D1%81%D0%BF%D0%B5%D1%80%D1%82&oos_search=false',
            handler: ()=>{activateModal()}
        },

        {
            article: '915-205',
            diameter: '28',
            length: '300',
            image: './images/link/pic05.png',
            linkVi: 'https://www.vseinstrumenti.ru/product/sverlo-po-armature-ekspert-28h300-mm-sds-plus-praktika-915-205-9835652/',
            linkOzon: 'https://www.ozon.ru/product/sverlo-po-armature-sds-plus-praktika-28-300-seriya-ekspert-1187721603/?asb=vwdIcV4qBK7b%252BUXFW3db4Y92tdBRvs6%252BpzlDCEEEhEs%253D&asb2=XiaFSqKzmMD113-PNEFzWtr-466qk58qBwWgJsD97Z4ItcdXqIRnVxBxHg-EjG4VGtWStwfQNLSqjs4Cqe_yZA&avtc=1&avte=2&avts=1714214270&from_sku=1187706260&from_url=https%253A%252F%252Fwww.ozon.ru%252Fsearch%252F%253Ffrom_global%253Dtrue%2526text%253D%2525D1%252581%2525D0%2525B2%2525D0%2525B5%2525D1%252580%2525D0%2525BB%2525D0%2525BE%252B%2525D0%2525BF%2525D0%2525BE%252B%2525D0%2525B0%2525D1%252580%2525D0%2525BC%2525D0%2525B0%2525D1%252582%2525D1%252583%2525D1%252580%2525D0%2525B5%252B%2525D1%25258D%2525D0%2525BA%2525D1%252581%2525D0%2525BF%2525D0%2525B5%2525D1%252580%2525D1%252582&keywords=%D1%81%D0%B2%D0%B5%D1%80%D0%BB%D0%BE+%D0%BF%D0%BE+%D0%B0%D1%80%D0%BC%D0%B0%D1%82%D1%83%D1%80%D0%B5+%D1%8D%D0%BA%D1%81%D0%BF%D0%B5%D1%80%D1%82&oos_search=false',
            handler: ()=>{activateModal()}
        },

        {
            article: '915-212',
            diameter: '32',
            length: '300',
            image: './images/link/pic06.png',
            linkVi: 'https://www.vseinstrumenti.ru/product/sverlo-po-armature-ekspert-32h300-mm-sds-plus-praktika-915-212-9835646/',
            linkOzon: 'https://www.ozon.ru/product/sverlo-po-armature-sds-plus-praktika-32-300-seriya-ekspert-1187706260/?asb=vwdIcV4qBK7b%252BUXFW3db4Y92tdBRvs6%252BpzlDCEEEhEs%253D&asb2=XiaFSqKzmMD113-PNEFzWtr-466qk58qBwWgJsD97Z4ItcdXqIRnVxBxHg-EjG4VGtWStwfQNLSqjs4Cqe_yZA&avtc=1&avte=2&avts=1714214270&from_sku=1187706260&from_url=https%253A%252F%252Fwww.ozon.ru%252Fsearch%252F%253Ffrom_global%253Dtrue%2526text%253D%2525D1%252581%2525D0%2525B2%2525D0%2525B5%2525D1%252580%2525D0%2525BB%2525D0%2525BE%252B%2525D0%2525BF%2525D0%2525BE%252B%2525D0%2525B0%2525D1%252580%2525D0%2525BC%2525D0%2525B0%2525D1%252582%2525D1%252583%2525D1%252580%2525D0%2525B5%252B%2525D1%25258D%2525D0%2525BA%2525D1%252581%2525D0%2525BF%2525D0%2525B5%2525D1%252580%2525D1%252582&keywords=%D1%81%D0%B2%D0%B5%D1%80%D0%BB%D0%BE+%D0%BF%D0%BE+%D0%B0%D1%80%D0%BC%D0%B0%D1%82%D1%83%D1%80%D0%B5+%D1%8D%D0%BA%D1%81%D0%BF%D0%B5%D1%80%D1%82&oos_search=false',
            handler: ()=>{activateModal()}
        },

    ]

    let [currentItem, setCurrentItem] = useState(
        items[0]
    )

    function changeItem(item) {
        setCurrentItem(item)
    }

    function showDesktopVersion() {
        return (
            <div className={'desktop-how-to-buy-component'}>
                <HowToBuyImageComponent currentItem={currentItem}/>


                <div className={'how-to-buy-table'}>
                    <HowToBuyTableComponent
                        currentItem={currentItem}
                        handler={(item) => {
                            changeItem(item)
                        }}
                        params={params}
                        items={items}
                    />
                    <ProductLinkDropdownButtonComponent
                        linkVi={currentItem.linkVi}
                        linkOzon={currentItem.linkOzon}
                        handler={() => {
                            activateModal()
                        }}
                    />
                </div>

            </div>
        )
    }

    function showMobileVersion() {
        return (
            <div className={'mobile-how-to-buy-component'}>

                <HowToBuyTableComponent
                    currentItem={currentItem}
                    handler={(item) => {
                        changeItem(item)
                    }}
                    params={params}
                    items={items}
                />

                <HowToBuyImageComponent currentItem={currentItem}/>
                <ProductLinkDropdownButtonComponent
                    linkVi={currentItem.linkVi}
                    linkOzon={currentItem.linkOzon}
                    handler={() => {
                        activateModal()
                    }}
                />


            </div>
        )
    }
    function insideView() {
        return (
            <>
                {showDesktopVersion()}
                {showMobileVersion()}
            </>
        )
    }

    return (
        <>
            <SectionComponent
                title={'Где купить'}
                description={''}
                insideView={insideView()}
                id={'how-to-buy-component'}
                classSection={'section'}
                descriptionWidth={1235}
                descriptionTextAlign={'center'}
            />

            <ProductLinkFormComponent active={modalActive} handleClose={()=>{disableModal()}}/>

        </>



    )
}

HowToBuyComponent.propTypes = {
    
}

export default HowToBuyComponent
