import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import '../TopBar.css'
import './TopBarElement.css'
import {type} from "@testing-library/user-event/dist/type";

function TopBarElementComponent(props) {

    const [isSelected, setIsSelected] = useState(false)
    const [isOnceVisibled, setIsOnceVisibled] = useState(false)
    const visibilityHeight = props.visibilityHeight ? props.visibilityHeight : 1200

    useEffect(() => {
        if(isOnceVisibled) {
            const element = document.getElementById(props.target_id);
            element.style.animationName = 'fadeInUp'
        }
    }, [isOnceVisibled])


    useEffect(() => {
        const element = document.getElementById(props.target_id);
        if(element) {
            const isVisibleForAnimating = element.getBoundingClientRect()
                - props.animationOffset< window.innerHeight



            if(!isOnceVisibled && (isVisibleForAnimating || props.isFadeUpOnStart)) {
                setIsOnceVisibled(true)
            }

            const handleScroll = () => {
                const isVisible = element.getBoundingClientRect().top+500 < window.innerHeight
                    && element.getBoundingClientRect().top+visibilityHeight > window.innerHeight;
                setIsSelected(isVisible);
            };

            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }




    }, []);

    function scrollToSmoothly(pos, slowdown) {
        var currentPos = window.pageYOffset;
        window.requestAnimationFrame(function step() {

            if(Math.abs(pos - currentPos) > 10) {
                currentPos = currentPos+(pos-currentPos)/slowdown
                window.scrollTo(0, currentPos);
                window.requestAnimationFrame(step);
            }
        });
    }


    function handleClick() {
        const element = document.getElementById(props.target_id)
        scrollToSmoothly(element.getElementsByClassName('title')[0].offsetTop-120, 10)
    }

    if(isSelected) {
        return (
            <a className={'element-is-selected'}>
                {props.title}
            </a>
        )
    } else {
        return (
            <a className={'element'} onClick={() => {
                handleClick()
            }}>
                {props.title}
            </a>
        )
    }
}

TopBarElementComponent.propTypes = {
    title: PropTypes.string,
    target_id: PropTypes.string,
    animationOffset: PropTypes.number,
    visibilityHeight: PropTypes.number,
    isFadeUpOnStart: PropTypes.bool
}

export default TopBarElementComponent

